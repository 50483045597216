import { BehaviorSubject } from "rxjs";
import { ExecutorStore } from './AccountStore';
import { API } from '../helper/ApiHelper';
import { logIn, isLoginInProgress, oidcClient, authContext, adalConfig, replaceAuth } from '../authConfig';

export interface IUser {
    id?: number;
    firstName?: string;
    lastName?: string;
    email?: string;
    accountType?: string;
    userRights?: IUserRights;
}

export interface IUserRights {
    isSuperAdmin?: boolean;
    isTechnician?: boolean;
    isReader?: boolean;
    isEditor?: boolean;
    isExecutorAdmin?: boolean;
}

const user = new BehaviorSubject<IUser>({});
const token = new BehaviorSubject<string>("");
const tokenExpiryDate = new BehaviorSubject(Date.now());
const userLoggedIn = new BehaviorSubject(false);
const uumUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "uum/users";
const uumMigration = process.env.REACT_APP_UUM_MIGRATION as string;
let adalTimeout: NodeJS.Timeout | string | number | undefined = undefined;
let adalExpiryCheckTimeout: NodeJS.Timeout | string | number | undefined = undefined;

const getTokenFromCache = () => authContext.getCachedToken(adalConfig.clientId);

const setTokenRefresh = () => {
    clearTimeout(adalExpiryCheckTimeout);
    adalExpiryCheckTimeout = setTimeout(setTokenRefresh, 5000);

    if (!document.hidden && token.value != undefined && token.value.length > 0 && Date.now() > tokenExpiryDate.value)
        checkAuth();
}

const checkAuth = async () => {
    if (replaceAuth) {
        clearTimeout(adalTimeout);
        adalTimeout = setTimeout(checkAuth, 3000);
        const hasToken =  await oidcClient.hasToken()
        let tokenFromCache;
        if(hasToken){
            tokenFromCache = await oidcClient.getToken()
        }
        const isTokenStillValid = tokenFromCache?.access_token != undefined && (JSON.parse(atob(tokenFromCache.access_token.split('.')[1]))).exp * 1000 > Date.now();
        if (isTokenStillValid) {
            clearTimeout(adalTimeout);
            const tok = await oidcClient.refreshToken();
            if (tok) {
                token.next(tok.access_token);
                const tokenExpirationDate = (JSON.parse(atob(tok.access_token.split('.')[1]))).exp * 1000;  
                tokenExpiryDate.next(tokenExpirationDate);
                initializeUser();
                setTokenRefresh();
            }
        }
        else if (!isLoginInProgress() && !document.hidden) {
            logIn()
        }
    }
    else {
        clearTimeout(adalTimeout);
        adalTimeout = setTimeout(checkAuth, 3000);
        const tokenFromCache = getTokenFromCache();
        const isTokenStillValid = tokenFromCache != undefined && (JSON.parse(atob(tokenFromCache.split('.')[1]))).exp * 1000 > Date.now();
        if (isTokenStillValid) {
            clearTimeout(adalTimeout);
            authContext.acquireToken(
                process.env.REACT_APP_CLIENT_ID as string,
                () => {
                    const tok = sessionStorage.getItem('adal.idtoken');
                    if (tok != undefined) {
                        token.next(tok);
                        const tokenExpirationDate = (JSON.parse(atob(tok.split('.')[1]))).exp * 1000;
                        tokenExpiryDate.next(tokenExpirationDate);
                        initializeUser();
                        setTokenRefresh();
                    }
                }
            );
        }
        else if (!authContext.loginInProgress() && !document.hidden) {
            logIn()
        }
    }
}

const initializeUser = () => {
    if (userLoggedIn.value == false) {
        UserStore.initUser();
        userLoggedIn.next(true);
    }
}

const UserStore = {
    user,
    token,
    userLoggedIn,
    loginUser: async () => {
        if (replaceAuth) {
            if (token.value != undefined && token.value.length > 0) {
                initializeUser();
            }
            else {
                clearTimeout(adalTimeout);
                adalTimeout = setTimeout(checkAuth, 1000);
            }
        }
        else {
            if (token.value != undefined && token.value.length > 0) {
                initializeUser();
            }
            else {
                clearTimeout(adalTimeout);
                adalTimeout = setTimeout(checkAuth, 1000);
            }
        }
    },
    setUser: (resp: IUser) => {
        user.next(resp);
        ExecutorStore.initExecutorList();
    },
    initUser: function () {
        API.get<IUser>(uumUrl).then((resp) => {
            this.setUser(resp);
        }).catch((error) => {
            if (!!error.response && error.response.status == 428) {
                window.location.href = uumMigration;
            }
            else
                throw error;
        });
    }
}

export {
    UserStore,
    checkAuth
}