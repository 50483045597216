import { AuthenticationContext } from "react-adal";

export const adalConfig = {
    instance: process.env.REACT_APP_INSTANCE_ADAL,
    tenant: "adfs",
    clientId: process.env.REACT_APP_CLIENT_ID as string,
    endpoints: {
        "adfs": "https://login.festo.com",
    },
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    popUp: true,
};

export const authContext = new AuthenticationContext(adalConfig);

//Replacement///////////////////////////////////////////////////////////////////////////////////////////////////////////

import { ClientOptions, LogLevel, OidcClient, ResponseType, StorageType } from '@pingidentity-developers-experience/ping-oidc-client-sdk'

const pingOneConfig: ClientOptions = {
    client_id: '394bc3bb-6061-4e5e-b390-4b212b4d5ac2', //dev
    //client_id:  '3fb48332-a9df-4bc7-b254-c5061b19d3f5', //test
    redirect_uri: window.location.origin + "/",
    response_type: ResponseType.AuthorizationCode,
    scope: 'openid profile',
    usePkce: true,
    logLevel: LogLevel.Debug,
    storageType: StorageType.Session,
};

export let oidcClient: OidcClient;

export const initializeOidcClient = async () => {
    oidcClient = await OidcClient.initializeFromOpenIdConfig(
        'https://authdev.festo.com/as', //dev
        //'https://authstage.festo.com/as', //test
        pingOneConfig);
};

initializeOidcClient().catch(error => {
    console.error('Failed to initialize OIDC client:', error);
});

//Shared/////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const replaceAuth = false;


let loginInProgress = false;

export const isLoginInProgress = () => {
    return loginInProgress;
};

export const logIn = () => {
    return new Promise((resolve, reject) => {
        // Set loginInProgress to true when the login process starts
        loginInProgress = true;

        if (replaceAuth) {
            const popup = window.open('about:blank', 'oidc-login-popup', 'popup=true,width=500,height=600') as Window;
            oidcClient.authorizeWithPopup(popup)
                .then(() => {
                    loginInProgress = false; // Reset to false on success
                    resolve("Login successful");
                })
                .catch((error) => {
                    loginInProgress = false; // Reset to false on failure
                    reject("Login failed: " + error);
                });
        } else {
            authContext.login();
            loginInProgress = false; // Reset to false since it doesn't use a promise
            resolve("Login initiated");
        }
    });
};

export const logOut = () => {
    return new Promise((resolve, reject) => {
        if (replaceAuth) {
            oidcClient.endSession()
                .then(() => {
                    resolve("Logout successful");
                })
                .catch((error) => {
                    reject("Logout failed: " + error);
                });
        } else {
            const idToken = sessionStorage.getItem('adal.idtoken');
            if (idToken) {
                Object.entries(sessionStorage)
                    .filter(x => x[0].substring(0, 4) === "adal")
                    .forEach(x => sessionStorage.removeItem(x[0]));

                // Redirecting to logout URL
                window.location.href = `${process.env.REACT_APP_INSTANCE_ADAL}adfs/oauth2/logout?id_token_hint=${idToken}`;
                resolve("Logout initiated"); // Resolve immediately as the logout is initiated
            } else {
                reject("No ID token found for logout.");
            }
        }
    });
};

